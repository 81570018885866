import React from "react"
import Layer from "../components/Layer"
import Seo from "../components/Seo"

const TermsAndContidions = () => {
  return (
    <>
      <Seo title="Terms and conditions"/>
      <Layer>
        <main className="terms_and_conditions_main" style={{marginBottom: '40px'}}>
          <p>
            <br />
          </p>
          <h1>
            <b style={{ marginLeft: "25px", fontSize: 20 }}>SOLmate TERMS AND CONDITIONS</b>
          </h1>
          <p></p>
          <hr />
          <br />

          <ol>
            <p>
              <b>&lt;Last updated: 25 December 2021&gt;</b>
            </p>
            <p>
              <b>
                Please read these Terms carefully before using the SOLmate
                Platform because they affect your legal rights and obligations.
              </b>
            </p>
            <p>
              <b>
                By registering with SOLmate and using the SOLmate Platform,
                SOLmate Website or SOLmate Account you confirm that you have
                read, understood and accepted the terms and conditions of this
                user agreement (this "Agreement"). If you donâ€™t wish to be
                legally bound by these terms and conditions, then please do not
                register with SOLmate.
              </b>
            </p>
          </ol>
          <br />

          <ol>
            <b>1. INTRODUCTION</b>
            <p>
              1.1. Thank you for choosing SOLmate. SOLmate provides the SOLmate
              Platform which includes an online portal and mobile applications
              that give you access to different Services, including receiving
              and making payments and third party services. Some of these
              Services offered by Authorised Third Parties may however be
              available through the SOLmate Platform.
            </p>
            <p>
              1.2. Please note that SOLmate is not a registered bank in any
              jurisdiction.
            </p>
            <p>
              1.3. SOLmate is a duly appointed juristic representative of PAL
              LIFE, (Registration Number 2009/212012/23) which is an authorised
              Financial Services Provider under the Financial Advisory and
              Intermediary Services Act No 37 of 2002 â€“ FSP#43259. As such
              SOLmate renders financial services to clients on behalf of PAL
              LIFE.
            </p>
            <p>
              1.4. SOLmate will be entitled to, but not be under any obligation,
              tomonitor your use of the SOLmate Services. SOLmate may in its
              sole discretion, immediately and without notice, suspend or
              terminate your access to and use of the Services if we reasonably
              suspect you to be using the Services in breach of these Terms or
              any Applicable Laws.
            </p>
          </ol>
          <br />

          <ol>
            <b>2. INTERPRETATION AND DEFINITIONS</b>
            <p>
              2.1. "Account" or "SOLmate Account" means the account that a User
              creates and registers through the Website or associated mobile
              applications in order to access the SOLmate Platform and Services;
            </p>
            <p>
              2.2. â€œAdministratorâ€ means PAL LIFE, (Registration Number
              2009/212012/23) which is an authorised Financial Services Provider
              under the Financial Advisory and Intermediary Services Act No 37
              of 2002 â€“ FSP#43259, is the company that is approved by the
              Financial Services Board to provide administration services in
              respect of financial products on the instructions of SOLmate
              users.
            </p>
            <p>
              2.3. "Applicable Laws" means any law, by-law, ordinance,
              proclamation and/or statutory regulation which SOLmate or the User
              are required to observe by reason of the provision of the
              Services, use of or business with the Services and matters
              incidental thereto, including, but not limited to, any present or
              future legislation, measure, requirement, order, ordinance, rule,
              guideline, practice, concession, or request issued by any relevant
              authority, government body, agency or department, which is
              applicable to these Terms;
            </p>
            <p>
              2.4. "Authorised Third Parties" means third party banking, payment
              card, payment or other service providers, including service
              providers of value added services, which SOLmate has authorised to
              provide Services through the SOLmate Platform;
            </p>
            <p>
              2.5. "Beta Products" means all products or services still in beta
              testing phase;
            </p>
            <p>
              2.6. "Fees" means the fees payable in relation to any Services and
              as more fully set out on the Website;
            </p>
            <p>
              2.7. "KYC" refers to Know Your Customer and "KYC practices" means
              the practices implemented by SOLmate in relation to KYC;
            </p>
            <p>2.8. "Local Currency" means South African Rands;</p>
            <p>2.9. "Minimum Closure Amount" means ZAR 10.00;</p>
            <p>
              2.10. "Minimum Unallocated Deposit Amount" shall mean, until
              otherwise determined by SOLmate, ZAR 10.00.
            </p>
            <p>
              2.11. "Personal Information" means all information about a User
              that can be used to identify the User;
            </p>
            <p>
              2.12. "Services" means the SOLmate Platform and the services
              supplied or made available by SOLmate or Authorised Third Parties
              to Users through the SOLmate Platform;
            </p>
            <p>2.13. "SOLmate", "us" and "we" means:</p>
            <ol>
              1) AK SOLWALLET SERVICES LTD (company registration number HE
              402392), a company incorporated in terms of the laws of Cyprus,
              with its physical address at Arch. Kuprianou &amp; Agiou Antreou
              1, LOUKAIDES BUILDING, 2nd floor, office 21, Limassol, Cyprus,
              3036;
              <br />
              2) SOL ECOSYSTEM (PTY) LTD (CIPC reg.no 2020/851425/07), a company
              incorporated in terms of the laws of South Africa, with its
              physical address at 77 Andre Brink street, Vorna Valley, Midrand,
              1686.
            </ol>
            <p>
              2.14. "SOLmate Platform" means the Website, any associated mobile
              applications, websites or APIs to the Website;
            </p>
            <p>
              2.15. "SOLmate Platform Chat" means the communication function
              (Chat window) built into Website and associated mobile
              applications, or any designated link or contact number in other
              communication platforms (such as Whatsapp, Telegram, Viber or
              others), as described in Website and associated mobile
              applications;
            </p>
            <p>
              2.16. <b>"SOLmate Mobile App"</b> or â€œSOLmate Appâ€ refers to
              the means through which the SOLmate Account is accessed;
            </p>
            <p>
              2.17. "Terms" means these terms in this legally binding agreement
              that regulate your use of the Services and our relationship with
              you;
            </p>
            <p>
              2.18. "User" and "you" means the person making use of the
              Services;
            </p>
            <p>
              2.19. "Value Added Services" or "VAS" means value-added services
              such as mobile network airtime and/or pre-paid electricity;
            </p>
            <p>
              2.20. "Website" means{" "}
              <a href="https://solmate.co.za/">www.solmate.co.za</a>,{" "}
              <a href="https://solcard.co.za/">www.solcard.co.za</a>, including
              all sub-domains.
            </p>
          </ol>
          <br />

          <ol>
            <b>3. USING THE SOLMATE APP AND SOLMATE ACCOUNT</b>
            <p>
              3.1. Once you have registered a SOLmate Account you can make
              payment of Local Currency into the specified SOLmate bank account
              that a SOLmate operating entity in your jurisdiction will operate
              and manage. The payments will be allocated to your SOLmate Account
              for transacting through the SOLmate Platform and for purchasing
              VAS or other Services offered by Authorised Third Parties only.
              You agree that SOLmate may share your Personal Information with
              the local SOLmate operating entity, global SOLmate operating
              entities, and Authorised Third Parties to process transactions as
              instructed by SOLmate.
            </p>
            <p>
              3.2. You will receive a reference number for every payment made
              which you must use as a reference where so required.
            </p>
            <p>3.3. You make payment subject to the following terms:</p>
            <ol>
              <p>3.3.1. You may not make payments by cheques;</p>
              <p>
                3.3.2. You may only make payments in a manner as specified on
                the Website or in the associated mobile applications;
              </p>
              <p>
                3.3.3. You may only make a payment for the purpose of purchasing
                goods or services through the SOLmate Platform;
              </p>
              <p>
                3.3.4. You may only make a payment in Local Currency and only
                from within South African borders;
              </p>
            </ol>
            <p>
              3.4. If you breach, or if SOLmate reasonably suspects that you are
              in breach of any of the terms in clause 3, SOLmate may suspend or
              terminate your SOLmate Account immediately, or there may be a
              delay in the payment being allocated to your SOLmate Account.
            </p>
            <p>
              3.5. In addition, you take note that delays may be caused by
              operational or managing processes or different processing times
              being applied by the different applicable banks. Although SOLmate
              will take all reasonable care to allocate all payments made in
              terms of this clause 3, SOLmate will not be liable for any delay
              or failure of a payment to reflect timeously.
            </p>
            <p>
              3.6. You will be able to request a withdrawal of the proceeds from
              a transaction through the SOLmate Platform or the advance payments
              available in your SOLmate Account if you no longer wish to proceed
              with transactions through your SOLmate Account.
            </p>
            <p>
              3.7. Where any Local Currency in your SOLmate Account is not used
              for a period exceeding three months, or you withdraw Local
              Currency from your SOLmate Account in less than 5 days from
              depositing it, without using for any transactions, then SOLmate
              may charge and obtain from you an "Inactivity Fee" as specified on
              the Website, which shall be collected from the Local Currency held
              in your SOLmate Account.
            </p>
            <p>
              3.8. If you retain funds in your SOLmate Account and SOLmate
              detects inactivity of your SOLmate Account as determined by
              SOLmate, SOLmate will notify you through the SOLmate Platform Chat
              service. The laws may require us to report and deliver the funds
              to the relevant authorities as unclaimed property if we cannot get
              hold of you. If the law permits, SOLmate will deduct a fee for the
              inactivity as specified on the Website. In the event of SOLmate
              being unable to contact you and being required to deliver your
              funds to any relevant authority, you indemnify and hold SOLmate
              harmless for any loss that you may suffer.
            </p>
            <p>
              3.9. If you make a payment of less than ZAR 10.00 or the
              equivalent in your local currency that we cannot allocate within a
              month of being deposited, SOLmate will retain the amount as a
              service fee and you accept and agree that you shall have no
              further claim or entitlement to such funds.
            </p>
            <p>
              3.10. As part of the Services, we may offer that you purchase
              Value Added Services like airtime or pre-paid electricity or other
              Services from Authorised Third Parties through the SOLmate
              Platform. These transactions will be subject to the agreement
              entered into with the Authorised Third Party and SOLmate will not
              be liable for any claim against or dispute between you and the
              Authorised Third Party.
            </p>
            <p>
              3.11. You must take note that in some cases the agreement with the
              Authorised Third Party will require direct instant payment from
              your SOLmate Account to settle the purchase price of the goods or
              services purchased from the Authorised Third Party.
            </p>
            <p>
              3.12. Even though SOLmate may implement reasonable KYC processes,
              SOLmate cannot warrant Usersâ€™ identities and you accept
              responsibility for the correctness of all transaction details
              before you submit an instruction to proceed with a transaction
              through the SOLmate Platform.
            </p>
          </ol>
          <br />

          <ol>
            <b>4. WITHDRAWING FROM YOUR SOLMATE ACCOUNT </b>
            <p>
              4.1. If you want to withdraw funds or if you no longer wish to
              proceed with transactions through your SOLmate Account, you may
              request from SOLmate to withdraw the proceeds or advance payments
              made in relation to the Services and pay it over to your local
              bank account if you have added your local bank account details to
              your SOLmate Account. SOLmate will process all such requests in
              terms of your instructions as soon as reasonably possible, but
              cannot guarantee any time period. Any other withdrawal processes
              will be specified on the Website.
            </p>
            <p>
              4.2. You are solely responsible to provide complete and correct
              banking details when adding your bank account details to your
              SOLmate Account and SOLmate will not be liable in the case where
              you provide incorrect information. Once SOLmate has acted on your
              instructions it would not be possible to reverse the withdrawal.
            </p>
          </ol>
          <br />

          <ol>
            <b>5. FEES </b>
            <p>
              5.1. The detailed information on Fees payable for the different
              Services available through your SOLmate Account and the SOLmate
              Platform are set out on the Website or where applicable on the
              Authorised Third Partyâ€™s website. Note that Fees may change from
              time to time without notice and the Fees as set out on the Website
              when you make use of the Service, apply at the time. A change in
              Fee will not affect any completed transactions.
            </p>
            <p>5.2. Examples of the Fees we may charge include:</p>
            <ol>
              <p>5.2.1. Send and Receive fees;</p>
              <p>5.2.2. Deposit and Withdrawal fees;</p>
              <p>5.2.3. Exchange fees;</p>
              <p>5.2.4. Subscription fees;</p>
              <p>5.2.5. Inactivity Fees;</p>
              <p>5.2.6. Transaction Fees.</p>
            </ol>
          </ol>
          <br />

          <ol>
            <b>6. PAYMENTS</b>
            <p>
              6.1. <b>Payment:</b> payment of the Fees is required before you
              can use the Services. You warrant that you are authorised to use
              the relevant billing details provided when paying the Fees, and we
              reserve the right to terminate any Service if you are not
              authorised to use such details. You must ensure that there are
              enough funds to cover the Fees when they are billed.
            </p>
            <p>
              6.2.
              <b>
                Under no circumstances will SOLmate be responsible for any Fees
                incurred by you to an Authorised Third Party or any other third
                parties.
              </b>
            </p>
            <p>
              6.3. <b>Making payment:</b> Fees are paid through direct set off
              against the available balance of Local Currency in your SOLmate
              Account at the applicable exchange rate at the time.
            </p>
            <p>
              6.4. <b>Transaction records:</b> we will make all documentation
              relating to transactions between you and us available to you in
              your SOLmate Account.
            </p>
            <p>
              6.5. <b>Taxes:</b> all Fees exclude any applicable taxes unless
              stated otherwise. To the extent allowed under Applicable Laws, the
              User is responsible for any applicable taxes, whether they are
              listed on the transaction documents or not.
            </p>
            <p>
              6.6. <b>Failed purchase:</b> should payment for the Fees be
              unsuccessful, the User will be notified of this and will be able
              to attend to payment again. In the event of a failed purchase, the
              User will be unable to access the Services until the Fees have
              been successfully paid. If the Fees remain unpaid, SOLmate
              reserves the right to terminate your access to the Services and
              shall notify you of such termination through the SOLmate Platform
              Chat service in your SOLmate Account.
            </p>
          </ol>
          <br />

          <ol>
            <b>7. AUTHORIZED THIRD PARTIES;</b>
            <p>
              7.1. <b>SOLmate Card </b>
            </p>
            <ol>
              <p>
                7.1.1. <b>â€œSOLmate Cardâ€ </b>means a physical or virtual
                payment card, which may be issued by an Authorized Third Party
                and connected to your SOLmate Account at your request. SOLmate
                has authorised a card issuing Third Party to provide Services
                through the SOLmate Platform;
              </p>
              <p>
                7.1.2. SOLmate is not an owner or an issuer of the SOLmate Card,
                and only provides services of delivering, activating, and
                topping up the SOLmate Card using the Local Currency stored in
                your SOLmate Account. SOLmate may charge you fees for these
                services as described in the Website;
              </p>
              <p>
                7.1.3. SOLmate will not be liable to you in respect of any
                Authorised Third-Party Services made available to you, or Fees
                charged by Authorized Third Party for using the SOLmate Card,
                even if such Authorised Third Party Services are made available
                to you through the SOLmate Platform;
              </p>
              <p>
                7.1.4. SOLmate will use all reasonable endeavours to assist you
                in case of any issues with Authorised Third-Party Services or
                Fees, including SOLmate Card. To get such assistance, you may
                contact SOLmate via the SOLmate Platform Chat or associated
                Whatsapp numbers.
              </p>
            </ol>
            <p>
              7.2.{" "}
              <b>
                SOLmate Group Funeral Cover Terms, Conditions and Disclosures{" "}
              </b>
            </p>
            <ol>
              <p>7.2.1. Your cover is for R5,000 for you only.</p>
              <p>
                7.2.2. Should you cancel your SOLmate debit card, or your
                monthly card fee is not received, cover will cease as it is
                embedded with your card program.
              </p>
              <p>
                7.2.3. The Cover Start Date is when the first monthly card fee
                is received
              </p>
              <p>
                7.2.4. Claim payment in the event of your death is payable to
                your nominated Beneficiary directly or via the Intermediary for
                onwards payment to your nominated Beneficiary.
              </p>
              <p>
                7.2.5. The nominated Beneficiary has to be 18 years or older.
              </p>
              <p>
                7.2.6. A 6 (six) month waiting period will apply from the Cover
                Start Date. o No Waiting Period will apply for Accidental Cover,
                provided first monthly card fee has been received.
              </p>
              <p>
                7.2.7. A 12 (twelve) month Waiting Period will apply in respect
                of suicide.
              </p>
              <p>
                7.2.8. No Policy Benefits are payable in the event of the
                occurrence of your death arising directly or indirectly from, or
                traceable to war, riots, civil commotion, terrorist activities,
                wilful exposure to danger, or you being under the influence of
                any drugs or alcohol; participation in any criminal act;
                radioactivity or nuclear explosions or intentional
                self-inflicted injury.
              </p>
              <p>
                7.2.9. Should your death occur outside the borders of South
                Africa, such claim will be subject to receipt of the official
                proof of death from another country, which Stangen may or may
                not be in a position to verify. Payments of claims under such
                circumstances can therefore not be guaranteed.
              </p>
              <p>
                7.2.10. The information provided and all declarations made by
                the Applicant form the basis of this Policy. This Policy and
                Cover shall be voidable in the event of misrepresentation or
                non-disclosure.
              </p>
              <p>
                7.2.11. You accept that the Insurer can communicate with you
                telephonically and/or electronically. All records shared with
                the Insurer may be stored electronically. The Insurer values
                clients' privacy and personal information. The detailed Privacy
                Policy can be viewed online on the Insurerâ€™s website.
              </p>
              <p>
                7.2.12. When you enter into this policy, you will be giving us
                your personal information that may be protected by data
                protection legislation, including but not only, the Protection
                of Personal Information Act, 2013 (POPI). We will take all
                reasonable steps to protect your personal information. You
                authorise the Intermediary and the Insurer to:
              </p>
              <ol>
                <ul>
                  <li>
                    Process your personal information to communicate information
                    to you that you ask us for, provide you with insurance
                    services, verify the information you have given us against
                    any source or database, compile non-personal statistical
                    information about you and send you marketing material and /
                    or communication for direct marketing for similar products /
                    services.
                  </li>
                  <li>
                    Transmit your personal information to any affiliate,
                    subsidiary or re-insurer so that we can provide insurance
                    services to you and to enable us to further our legitimate
                    interests including statistical analysis, re-insurance and
                    credit control.
                  </li>
                  <li>
                    Transmit your personal information to any third-party
                    service provider that we may appoint to perform functions
                    relating to your policy on our behalf.
                  </li>
                </ul>
              </ol>

              <p>
                7.2.13. You acknowledge that this consent clause will remain in
                force even if your policy is cancelled or lapses.
              </p>
              <p>
                7.2.14. In the event of a claim, the Intermediary should be
                contacted.
              </p>
              <p>
                7.2.15. The occurrence of your death must be reported in writing
                within 12 (twelve) months.
              </p>
              <p>
                7.2.16. No claim shall be considered, or Benefit paid out under
                this Policy if the claimant is unable to furnish documentation
                acceptable to Stangen, which are positive verification of your
                death.
              </p>
              <p>
                7.2.17. If any claim under this Policy is in any respect
                fraudulent, or if any fraudulent means are used by the
                Policyholder or anyone acting on their behalf to obtain any
                Policy benefit under this Policy, such claims shall not be
                honoured and Stangen will have the right to cancel the Policy at
                its discretion.
              </p>
              <p>
                7.2.18. Any complaints must first be lodged with the
                Intermediary. Should the resolution not be satisfactory, the
                Insurer can be contacted on the numbers below or via e-mail to
                complaints@stangen.co.za. Any complaints must be submitted in
                writing.
              </p>
              <p>
                7.2.19. Should there be concerns about the information received,
                the Insurer's Compliance Department can be contacted on 086 11
                22 222 or insurancecompliance@stangen.co.za
              </p>
              <p>
                7.2.20. The Insurer:
                <br />
                The Standard General Insurance Company Limited (â€œStangenâ€)
                <br />
                Licensed Insurer
                <br />
                Tel Nr. 0861 007 966
                <br />
                Menlyn Corporate Park, Corobay Avenue, Waterkloof Glen,
                Pretoria, 0081
                <br />
              </p>
              <p>
                7.2.21. The Intermediary:
                <br />
                Essential Employee Benefits (Pty) Ltd
                <br />
                Approved Financial Service Provider (FSP Nr 46244)
                <br />
                Tel Nr. 010 593 7158
                <br />
                11 Wellington Road, Parktown, Johannesburg, 2193
                <br />
              </p>
              <p>
                7.2.22. The Policyholder:
                <br />
                SOL Ecosystem (Pty) Ltd
                <br />
                Whatsapp support number: 063 944 5086
                <br />
                77 Andre Brink Street, Vorna Valley, Midrand, 16 86
                <br />
              </p>
              <p>
                7.2.23. You can access the SOLMATE STANGEN COMPULSORY GROUP
                FUNERAL COVER Master Policy document at SOLmate Website:
                <br />
                <a href="https://solmate.co.za/MasterPolicy">
                  https://solmate.co.za/MasterPolicy
                </a>
                <br />
                <br />
              </p>
            </ol>
          </ol>
          <br />

          <ol>
            <b>8. IMPORTANT DISCLAIMER AND RESTRICTIONS </b>
            <p>
              8.1.
              <b>
                It is your sole responsibility to identify, understand and
                comply with all Applicable Laws that may apply in your
                jurisdiction when you access or use the SOLmate Services. These
                include for example applicable tax, exchange control, import and
                export and foreign currency laws. You take note that your
                jurisdiction may not allow use of the SOLmate Service in which
                case you agree not to use the SOLmate Service.
              </b>
            </p>
            <p>
              8.2. Authorised Third Party Services made available through the
              SOLmate Platform will be regulated by the terms and conditions of
              the applicable Authorised Third Party and SOLmate will not be a
              party to the agreement between you and the Authorised Third Party.
              <b>
                SOLmate will not be liable to you in respect of any Authorised
                Third-Party Services made available to you, even if such
                Authorised Third Party Services are made available to you
                through the SOLmate Platform.
              </b>
            </p>
            <p>
              8.3.
              <b>
                SOLmate does not endorse the accuracy or reliability of any
                information, statement, opinion, or other content contained on
                the Website or provided in the Services, and such content does
                not constitute legal, financial, professional or other advice
                and should not be used to obtain credit, make investments of any
                nature or make financial decisions or for any other commercial
                purposes.
              </b>
            </p>
            <p>
              8.4.
              <b>
                All information provided on the Website or with the Services are
                provided for information purposes only. You should therefore not
                rely on it when making any decisions or when transacting through
                the SOLmate Platform.
              </b>
            </p>
            <p>
              8.5.
              <b>
                If you rely on the information on the Website or with the
                Services, you do so strictly at your own risk. SOLmate will not
                accept any responsibility for any loss whatsoever which may
                arise from reliance on information or materials published on the
                SOLmate Platform or with the Services.
              </b>
            </p>
            <p>
              8.6.
              <b>
                All information provided on the SOLmate Platform and with the
                Services is the intellectual property of SOLmate and are subject
                to these Terms and Applicable Laws.
              </b>
            </p>
            <p>
              8.7.
              <b>
                You agree to indemnify and hold SOLmate, its officers, directors
                and employees harmless from any losses (including attorneysâ€™
                fees) that result from any third party claims related to a
                Userâ€™s access, use or misuse of the product(s) and service(s),
                or any act or omission by a User in violation of this Agreement.
              </b>
            </p>
            <p>
              8.8.
              <b>
                No information available on the SOLmate Platform or in the
                Services or provided by any employee, agent or affiliate of
                SOLmate, will constitute financial, investment, other
                professional or other general advice.
              </b>
            </p>
            <p>
              8.9.
              <b>
                You are solely responsible for any decisions in relation to the
                use the SOLmate Platform, and such decision should take into
                account your risk tolerance and financial circumstances. You
                should consult your legal, tax, investment and other
                professional advisors before using the Services.
              </b>
            </p>
            <p>
              8.10.
              <b>
                You may be liable for payment of tax in relation to any
                transactions performed through the Services. It is your
                responsibility to obtain your own tax advice and you will be
                liable for the payment of any tax that you may be liable for in
                any jurisdiction resulting from your use of the SOLmate Platform
                and Services. SOLmate will not be liable for any taxes in
                relation to transactions through the Services.
              </b>
            </p>
            <p>
              8.11.
              <b>
                International export controls, economic sanctions requirements
                or other regulatory approvals may apply or be required when you
                use the Services. It is your sole responsibility to ensure that
                you comply with any of these requirements and obtain the
                required approvals. You may not use the SOLmate Platform or
                Services if:
              </b>
            </p>
            <ol>
              <p>
                8.11.1.
                <b>
                  you are in or under the control of, or a resident of any
                  country subject to United States embargo, UN sanctions, the HM
                  Treasury financial sanctions regime;
                </b>
              </p>
              <p>
                8.11.2.
                <b>
                  you are on the list of the U.S. Treasury Department's
                  Specially Designated Nationals List or the U.S. Commerce
                  Department's Denied Persons List, Unverified List, Entity
                  List, or HM Treasury's financial sanctions regime;
                </b>
              </p>
              <p>
                8.11.3.
                <b>
                  you intend to supply any acquired or stored value, or transact
                  with people or businesses operating in any country that is
                  subject to United States embargo or HM Treasury's financial
                  sanctions regime, or to a person on the Specially Designated
                  Nationals List, Denied Persons List, Unverified List, Entity
                  List, or HM Treasury's financial sanctions regime; or
                </b>
              </p>
              <p>
                8.11.4.
                <b>
                  you are a South African resident and intend to use the SOLmate
                  Platform to transfer Local Currency to persons or entities
                  that are not South African residents, if such transfers are
                  done in contravention of South African Exchange Control
                  Regulation 10(1)(c);
                </b>
              </p>
            </ol>
            <p>
              <b>
                and SOLmate reserves the right to suspend or terminate your
                SOLmate Account immediately and/or to restrict or prohibit any
                or all of your transactions if you breach, or if SOLmate
                reasonably suspects that you are in breach of the above
                restrictions or any other Applicable Laws and SOLmate reserves
                the right to report any such suspect activities to the relevant
                regulatory authorities without warning or notice to you.
              </b>
            </p>
          </ol>
          <br />

          <ol>
            <b>9. WHEN DO THESE TERMS APPLY? </b>
            <p>
              9.1. These Terms apply when you use the SOLmate Platform or the
              Services and when you register for and open a SOLmate Account.
            </p>
            <p>
              9.2. We reserve the right to refuse any request for our Services
              without notice or reason.
            </p>
            <p>
              9.3. These Terms will also apply to any future services and
              interaction channels that may be made available by us unless
              stated otherwise.
            </p>
          </ol>
          <br />

          <ol>
            <b>10. OTHER APPLICABLE TERMS</b>
            <p>
              Your use of the Services will be regulated by these Terms as well
              as any other terms that are available on the Website{" "}
              <b>("the Additional Terms").</b> The Additional Terms include the
              privacy policy governing the use of your Personal Information{" "}
              <b>("the Privacy Policy")</b> and any other specific terms
              applicable to a specific Service. If there is a conflict between
              these Terms and any of the Additional Terms, the following order
              of preference will be applied in respect of applying such terms:
              (1) any specific terms relating to a specific Service; (2) these
              Terms; and (3) the Privacy Policy.
            </p>
          </ol>
          <br />

          <ol>
            <b>11. CHANGES TO THESE TERMS</b>
            <p>
              We may change or add to these Terms, change or cancel the Services
              or offer new Services to you from time to time, change or remove
              the Website from time to time, at our discretion. We will notify
              you of any material changes with a prominent notice on the Website
              and through the SOLmate Platform Chat service in your Account,
              each time that you access the SOLmate Platform or Account. You may
              only continue to use the Services, if you agree to the new or
              amended Terms applicable at the time.
            </p>
          </ol>
          <br />

          <ol>
            <b>12. DURATION OF THESE TERMS</b>
            <p>
              Simply, these Terms (as amended) apply for as long as you use our
              Services, which shall include the period until any disputes have
              been settled in the event of any disputes arising from the use of
              the Services.
            </p>
          </ol>
          <br />

          <ol>
            <b>13. USER RISKS </b>
            <p>
              13.1. Your financial and investment situation and needs and your
              risk tolerance may result in you not being a suitable User of the
              Services to use SOLmate Platform and SOLmate Account.
            </p>
            <p>
              13.2. You have taken note of these and any other relevant risks
              relating to the transactions available through the Service and
              accept and agree that you are solely responsible for any decision
              to register for and use the Services.
            </p>
          </ol>
          <br />

          <ol>
            <b>14. YOUR ACCOUNT AND INFORMATION</b>
            <p>
              14.1. By accepting this agreement, you acknowledge that SOLmate
              may transfer your personal information to other countries outside
              South Africa. SOLmate servers are located in the cloud platform
              hosted by Amazon Inc in several datacenters worldwide and are
              owned by the SOLmate HQ company in the UK (SOL ECOSYSTEM LTD,
              company number 08198283). SOLmate will only transfer your personal
              information to SOL ECOSYSTEM LTD (UK) for the purpose of storing
              it and facilitating your transactions on SOLmate platform. The
              list of transmitted personal information include:
            </p>
            <ol>
              <ul>
                <li>
                  name, surname, passport or ID details, date of birth, race,
                  gender, nationality
                </li>
                <li>transactions history</li>
                <li>history of actions in the SOLmate platform</li>
                <li>
                  documents uploaded for verification, FICA or other compliance
                  purposes
                </li>
                <li>
                  history of chat between the user and SOLmate support team
                </li>
                <li>emails sent to SOLmate support team</li>
              </ul>
            </ol>
            <p>
              14.2. SOLmate has an active data processing agreement in place
              between SOLmate and SOL ECOSYSTEM (UK) which protects your
              personal data from being used for any purposes except providing
              the Services. SOLmate ECOSYSTEM (UK) is obliged to perform best
              possible efforts to protect the data during transmission, storage
              and processing - by technical means of access control and secured
              traffic encryption.
            </p>
            <p>
              14.3. In order to access the Services, you must create an Account
              by providing the required information to SOLmate. SOLmate will
              process your Personal Information in accordance with its privacy
              policy on the Website. Once you have created an Account, you will
              be a User for purposes of these Terms. You must keep you
              information up to date.
            </p>
            <p>
              14.4. You may not provide any Personal Information of third
              parties to SOLmate and you agree to only provide your own Personal
              Information.
            </p>
            <p>
              14.5. You are solely responsible to keep your Account access
              credentials safe. This means that should anyone enter your Account
              access credentials (whether that be you, or anyone else), we may
              assume that the person using the SOLmate Platform or Services is
              you.
            </p>
            <p>
              14.6. You are not allowed to share your Account with other Users.
              Only you may use your Account to access the SOLmate Platform and
              Services and you may only use your Account and the Services for
              your own personal purposes. You are strictly prohibited from
              sub-licensing or commercially exploiting your Account or the
              Services.
            </p>
            <p>
              14.7. It is in your interests to familiarise yourself with our
              security requirements, guidelines and procedures that we
              communicate from time to time and you should follow these
              carefully.
            </p>
            <p>
              14.8. You must inform us immediately if there has been, or if you
              suspect, any breach of security confidentiality on your Account,
              and update your Account access credentials.
            </p>
            <p>
              14.9. SOLmate will process all transactions in relation to your
              SOLmate Account on your or your purported instructions and will
              accept all instructions in relation to your SOLmate Account to be
              actual instructions from you.
            </p>
            <p>
              14.10. You have an obligation to notify SOLmate immediately via
              email at support@solmate.co.za if you have received Local Currency
              in error, or if you suspect, or should reasonably have known or
              suspected that you have received it in error. You have no rights
              to such receipts and must act on SOLmate's instructions to return
              it.
            </p>
            <p>
              14.11. SOLmate implements reasonable measures to protect the
              security of your SOLmate Account as set out on the Website. Please
              consider all messages received from an address that purports to be
              from SOLmate carefully. All messages from SOLmate will be
              available on your SOLmate Account if you log in to the Website or
              associated mobile applications and will also be available through
              the SOLmate Platform Chat service in your Account. You should not
              act on any messages from domains other than solmate.co.za,
              solcard.co.za.
            </p>
            <p>
              14.12. You remain solely responsible to ensure that no third party
              accesses your SOLmate Account by securing your log on and sign in
              details, your password, personal identification number or any
              other information relating to your SOLmate Account and using any
              available security measures. You are also responsible for the
              security of your email account and phone number and two-factor
              authentication applications or devices relating to your SOLmate
              Account.
            </p>
            <p>
              14.13. If you donâ€™t keep your SOLmate Account secure,
              unauthorised access to your SOLmate Account may result in Local
              Currency in your SOLmate Account being lost. SOLmate is not
              responsible or liable for any unauthorised access to your SOLmate
              Account, unless as a result of SOLmateâ€™s actions or omissions.
            </p>
            <p>
              14.14. Attacks on your computer or equipment may compromise the
              security of your SOLmate Account. These typically include
              phishing, spoofing, computer viruses, spyware, scareware, Trojan
              horses, worms or other malware that may affect your computer or
              other equipment. It is your responsibility to do regular virus
              screening and run the necessary software to protect your computer
              and equipment.
            </p>
            <p>
              14.15.
              <b>
                To the maximum extent permitted by applicable law, you accept
                and agree that you have full responsibility for all activity
                that occurs in or through your SOLmate Account and accept all
                risks of any unauthorised or authorised access to your SOLmate
                Account.
              </b>
            </p>
          </ol>
          <br />

          <ol>
            <b>15. KYC PROCESSES </b>
            <p>
              15.1. Whether required by applicable laws or not, SOLmate
              implements voluntary processes commonly referred to as Know Your
              Customer for purposes of combatting fraud and prevention money
              laundering and terrorist financing activities.
            </p>
            <p>
              15.2. These KYC processes include that you will be required to
              provide us with certain specified Personal Information and
              supporting documentation as we determine in our sole discretion
              and depending on various factors, such as your jurisdiction, the
              nature of the Services provided to you and the limits that apply
              to the transactions you perform through your Account on the
              SOLmate Platform. You may not be able to use your Account until
              these or any further KYC processes that SOLmate may require during
              the term of the relationship between us, have been completed.
            </p>
            <p>
              15.3. Where SOLmate requires KYC processes to be completed, you
              will not be able to complete a transaction until SOLmate is
              satisfied that you have complied with their KYC requirements.
            </p>
            <p>
              15.4. SOLmate will retain all KYC information for a reasonable
              period of time, considering internal business requirements and
              requirements in terms of applicable laws.
            </p>
          </ol>
          <br />

          <ol>
            <b>16. CANCELLATION AND SUSPENSION</b>
            <p>
              16.1. At any time, and without cause, we may cancel or suspend
              your Account or access to the Website and/or use of the Services
              in our sole discretion, without any liability if you are in breach
              of these Terms.
            </p>
            <p>
              16.2. In addition, SOLmate may cancel your Account, terminate
              these Terms and suspend SOLmate's obligations if:
            </p>
            <ol>
              <p>
                16.2.1. we become aware of circumstances that lead us to believe
                that you will not perform your obligations required by these
                Terms;
              </p>
              <p>
                16.2.2. you have used the Website to breach the intellectual
                property rights of any third party;
              </p>
              <p>
                16.2.3. you have not used your Account or the Services for a
                period that we regard you to become an inactive User; or
              </p>
              <p>
                16.2.4. you have suspended any payments due by you in terms of
                these Terms to us.
              </p>
            </ol>
            <p>
              16.3. Importantly, all our rights in respect of the
              confidentiality undertakings and our limitation of liability as
              set out below will survive the termination of these Terms.
            </p>
            <p>16.4. Upon termination of these Terms:</p>
            <ol>
              <p>
                16.4.1. you must make all reasonable efforts to delete all parts
                of the Services held by you in any format whatsoever;
              </p>
              <p>16.4.2. you must immediately cease all use of the Services;</p>
              <p>
                16.4.3. we may delete your SOLmate Account and you will no
                longer be able to access any portion of the Website or Services
                that requires a SOLmate Account; and
              </p>
              <p>
                16.4.4. we will retain your Personal Information generated as a
                result of the Services for a reasonable period in line with our
                Privacy Policy.
              </p>
            </ol>
          </ol>
          <br />

          <ol>
            <b>17. CLOSING YOUR SOLMATE ACCOUNT</b>
            <p>
              17.1. If you want to close your SOLmate Account and stop using the
              Services, you must send a request to SOLmate Platform Chat.
              SOLmate will close your SOLmate Account after any Local Currency
              in your SOLmate Account reaches the Minimum Closure Amount as
              referred to in clause 17.3 below and provided you did not transact
              through your SOLmate Account for at least the previous 3 (three)
              calendar days. These requirements are intended to protect you
              against loss.
            </p>
            <p>
              17.2. You will not have access to the SOLmate Platform after your
              SOLmate Account has been closed.
            </p>
            <p>
              17.3. If there is any Local Currency left in your SOLmate Account
              at the time of closing the Account and it exceeds the Minimum
              Closure Amount, SOLmate will retain the funds as a closing fee.
            </p>
          </ol>
          <br />

          <ol>
            <b>18. PROMOTIONAL CODES</b>
            <p>
              18.1. We may, at our discretion, make promotional codes available
              to you, providing a discounted fee.
            </p>
            <p>
              18.2. The promotional codes will be distributed for free by us and
              will be strictly enforced regarding any expiry date linked to such
              promotional code and other terms that may apply to the promotional
              code.
            </p>
          </ol>
          <br />

          <ol>
            <b>19. CHANGES TO THE SERVICES</b>
            <p>
              19.1. SOLmate shall inform Users of any material changes to the
              Services by manner of prominent notice on the Website and in the
              SOLmate Account. Continued use of the Services after this notice
              has been displayed shall be deemed as your acceptance of the
              changes.
            </p>
          </ol>
          <br />

          <ol>
            <b>20. ACCEPTABLE USE POLICY</b>
            <p>
              20.1. Users may specifically not use the Services for the direct
              benefit of their own clients. The Services are made available for
              the personal, non-commercial use of the User only.
            </p>
            <p>
              20.2. Some devices may not support the use of our Website or
              Services. It is your responsibility to keep your device(s) updated
              and/or in a condition for them to support the use of our Services,
              including internet access capabilities.
            </p>
            <p>
              20.3. The use of our Services may be restricted to certain
              jurisdictions. It is your responsibility to determine whether your
              location is supported by our Services before incurring any
              liability to us as we will not be liable for any loss that you may
              incur because of our Services not being supported in your
              jurisdiction.
            </p>
            <p>
              20.4. You must respect our Services and our intellectual property
              in utmost good faith and use it only as we intend it to be used.
              Any use by you of our Services which violates this undertaking can
              result in us terminating your use of our Services. We will be the
              sole judge of what constitutes a violation of your undertaking to
              use our Services, but these will likely be good grounds:
            </p>
            <ol>
              <p>
                20.4.1. copying or distributing any of the content on our
                Website without our explicit consent to do so;
              </p>
              <p>
                20.4.2. providing any untrue or incorrect information to us;
              </p>
              <p>
                20.4.3. changing, modifying, copying, decompiling,
                circumventing, disabling, tampering with any part of our
                Website, including the security features or reverse engineering
                our Website;
              </p>
              <p>
                20.4.4. infecting our Website with any software, malware or code
                that may infect, damage, delay or impede the operation of our
                Website or which may intercept, alter or interfere with any data
                generated by or received through our Website;
              </p>
              <p>
                20.4.5. using malicious search technology, including, but not
                limited to, spiders and crawlers;
              </p>
              <p>
                20.4.6. deep linking to any pages of our Website or engaging in
                any other conduct in a way to suggest that you are the owner of
                any intellectual property in our Services;
              </p>
              <p>
                20.4.7. allowing any third party to use your Account in any
                manner other than as permitted by these Terms; or
              </p>
              <p>
                20.4.8. using our Website to post any material which, in our
                discretion, is false, defamatory, inaccurate, abusive, vulgar,
                hateful, harassing, obscene, profane, sexually oriented,
                threatening, invasive of a person's privacy, or otherwise
                violates any laws.
              </p>
            </ol>
          </ol>
          <br />

          <ol>
            <b>21. ELECTRONIC MESSAGES AND COMMUNICATION</b>
            <p>
              21.1. You consent and agree that we will use electronic notices on
              the Website and through the SOLmate Platform as our communication
              tool for all communications relating to our Services or these
              Terms. If you object to receive electronic communications in this
              manner, SOLmate will not be able to offer the Services to you and
              will cancel your SOLmate Account.
            </p>
            <p>
              21.2. It is your responsibility to ensure that you have and keep
              updated the necessary hardware, software, connectivity and where
              applicable a valid email address to receive and retain all
              electronic communications. You can update your contact details
              through your Account by using the Settings page on the Website.
            </p>
            <p>
              21.3.
              <b>
                Please note that by accepting these Terms and using our
                Services, you acknowledge that we may use your personal contact
                information provided by you for communicating with you via
                electronic messages and communication in terms of applicable
                data protection laws. This includes us sending you direct
                marketing communications. You can opt-out from receiving further
                direct marketing messages at any point in time.
              </b>
            </p>
          </ol>
          <br />

          <ol>
            <b>22. SOCIAL MEDIA INTEGRATION</b>
            <p>
              22.1. The Website may allow you to create an account, profile, log
              in and/or share information through connecting to various social
              media sites or apps, including Facebook, Twitter, LinkedIn, and
              Instagram.
            </p>
            <p>
              22.2. If you proceed to use our Services in such context, then the
              relevant social media websites' rules will also apply, and you
              agree to such integration.
            </p>
          </ol>
          <br />

          <ol>
            <b>23. THIRD PARTY SITES</b>
            <p>
              23.1. We may provide certain hyperlinks to Authorised Third Party
              websites or apps only for your convenience, and the inclusion of
              any hyperlinks or any advertisement of any Authorised Third Party
              on our Website does not imply endorsement by us of their websites
              or apps, their products, business or security practices or any
              association with its operators.
            </p>
            <p>
              23.2.
              <b>
                SOLmate is not responsible or liable for any rights you may have
                against the Authorised Third-Party for the delivery and quality
                of the goods and services purchased or the use of their
                platforms.
              </b>
            </p>
            <p>
              23.3.
              <b>
                If you access and use any Authorised Third-Party websites, apps,
                products, services, and/or business, you do that solely at your
                own risk.
              </b>
            </p>
          </ol>
          <br />

          <ol>
            <b>24. INTELLECTUAL PROPERTY RIGHTS</b>
            <p>
              24.1. You acknowledge and agree that all right, title and interest
              in, and to, any of our intellectual property (including but not
              limited to any copyright, trademark, design, logo, process,
              practice, or methodology which forms part of, or is displayed or
              used on the Services including, without limitation, any graphics,
              logos, designs text, button icons, images, audio clips, digital
              downloads, data compilations, page headers and software) is
              proprietary to us or the respective owner(s)' property and will
              remain our or the owner's property at all times.
            </p>
            <p>
              24.2. You agree that you will not acquire any rights of any nature
              in respect of that intellectual property by using our Services.
            </p>
          </ol>
          <br />

          <ol>
            <b>25. WARRANTIES AND REPRESENTATIONS</b>
            <p>
              <b>Subject to Applicable Laws:</b>
            </p>
            <p>
              <b>
                25.1. we give no guarantee of any kind concerning the content or
                quality of our Services and our Services are not to be
                considered as advice of any kind;
              </b>
            </p>
            <p>
              <b>
                25.2. we do not give any warranty (express or implied) or make
                any representation that our Services will operate error free or
                without interruption or that any errors will be corrected or
                that the content is complete, accurate, up to date, or fit for a
                particular purpose; and
              </b>
            </p>
            <p>
              <b>
                25.3. we make no representations to you, either express or
                implied, and we will have no liability or responsibility for the
                proper performance of the Services and/or the information,
                images or audio contained on the Website. Our Services are used
                at your own risk.
              </b>
            </p>
            <p>
              <b>25.4. you warrant to and in favour of us that:</b>
            </p>
            <ol>
              <p>
                <b>25.4.1. you are a South African resident or citizen;</b>
              </p>
              <p>
                <b>
                  25.4.2. you are not a United States of America resident or
                  citizen;
                </b>
              </p>
              <p>
                <b>
                  25.4.3. you are at least 18 years old and have the legal
                  capacity to agree to and be bound by these Terms; and/or
                </b>
              </p>
              <p>
                <b>
                  25.4.4. you will only open one SOLmate Account and only in
                  your own name and at all times only access or attempt to
                  access your own Account;
                </b>
              </p>
              <p>
                <b>
                  25.4.5. you will only use your SOLmate Account if you have the
                  required Local Currency available for the intended
                  transaction;
                </b>
              </p>
              <p>
                <b>
                  25.4.6. you will not carry out transactions on behalf of a
                  third party;
                </b>
              </p>
              <p>
                <b>
                  25.4.7. you will not use your SOLmate Account for any illegal
                  activities or in breach of any Applicable Laws; and
                </b>
              </p>
              <p>
                <b>
                  25.4.8. these Terms constitute a contract valid and binding on
                  you and enforceable against you.
                </b>
              </p>
            </ol>
            <p>
              <b>25.5. Each of the warranties given by you will:</b>
            </p>
            <ol>
              <p>
                <b>
                  25.5.1. be a separate warranty and will in no way be limited
                  or restricted by inference from the terms of any other
                  warranty or by any other words in these Terms;
                </b>
              </p>
              <p>
                <b>
                  25.5.2. continue and remain in force irrespective of whether
                  these Terms are active, suspended or cancelled; and
                </b>
              </p>
              <p>
                <b>25.5.3. be deemed to be material.</b>
              </p>
            </ol>
          </ol>
          <br />

          <ol>
            <b>26. LIMITED LIABILITIES</b>
            <p>26.1. To be clear:</p>
            <ol>
              <p>
                26.1.1. we will not be liable for any loss arising from your use
                of the Services or any reliance on the information presented on
                the Website or in the Services or provided by SOLmate as part of
                the Services;
              </p>
              <p>
                26.1.2. we will not be liable to you for any loss caused by
                using our Services or your liability to any third party arising
                from those Services. This includes but is not limited to:
              </p>
              <ol>
                <p>
                  26.1.2.1. any interruption, malfunction, downtime, off-line
                  situation or other failure of the Website, system, databases
                  or any of its components;
                </p>
                <p>
                  26.1.2.2. any loss or damage regarding your data or other data
                  directly or indirectly caused by malfunction of the Website;
                  and
                </p>
                <p>
                  26.1.2.3. any third-party systems whatsoever, power failures,
                  unlawful access to or theft of data, computer viruses or
                  destructive code on the Website, or third-party systems or
                  programming defects;
                </p>
              </ol>
              <p>
                26.1.3. we will not be liable if any material available for
                downloading from the Website is not free from infection, viruses
                and/or other code that has contaminating or destructive
                properties;
              </p>
              <p>
                26.1.4. the Website may include inaccuracies or typo's â€“ in
                such instances we can't be held liable and can't be forced to
                comply with offers that are genuinely (and/or negligently)
                erroneous;
              </p>
              <p>
                26.1.5. we are not responsible for the proper and/or complete
                transmission of the information contained in any electronic
                communication or of the electronic communication itself nor for
                any delay in its delivery or receipt. Security measures have
                been implemented to ensure the safety and integrity of our
                Services. However, despite this, information that is transmitted
                over the internet may be susceptible to unlawful access and
                monitoring; and
              </p>
              <p>
                26.1.6. finally, our limited liability applies to all and any
                kind of loss which we can possibly contract out of under law,
                including direct, indirect, consequential, special or other
                kinds of losses or claims which you may suffer.
              </p>
            </ol>
            <p>
              26.2. If an applicable jurisdiction does not allow an exclusion of
              liability on SOLmate, and if any matter results in a valid claim
              against SOLmate, SOL's liability will be limited to the Fees paid
              by the User in respect of the Services supplied under these Terms.
            </p>
          </ol>
          <br />
          <ol>
            <b>27. INDEMNITY</b>
            <p>
              You indemnify, defend and hold us (including our shareholders,
              directors and employees, in whose favour this constitutes a
              stipulation capable of acceptance in writing at any time), our
              affiliates and their employees and suppliers harmless from any and
              all third party claims, any, actions, suits, proceedings,
              penalties, judgments, disbursements, fines, costs, expenses,
              damages (including, without limitation, indirect, extrinsic,
              special, penal, punitive, exemplary or consequential loss or
              damage of any kind) and liabilities, including reasonable
              attorneys' fees, whether directly or indirectly arising out of,
              relating to, or resulting from negligence, intent, breach of these
              Terms or violation of applicable law, rule, regulation by a party
              or its affiliates, or their respective owners, officers,
              directors, employees, or representatives or any other action or
              omission of any nature.
            </p>
          </ol>
          <br />

          <ol>
            <b>28. FORCE MAJEURE</b>
            <p>
              Except for the obligation to pay the Fees, neither you nor we
              shall be liable if either of us cannot perform in terms of any
              agreed terms due to reasons beyond our control. This includes
              lightning, flooding, exceptionally severe weather, fire,
              explosion, war, civil disorder, industrial disputes, acts or
              omissions of persons for which we are not responsible, and acts of
              government or other competent authorities (including
              telecommunications and internet service providers).
            </p>
          </ol>
          <br />

          <ol>
            <b>29. REGULATORY RISK</b>
            <p>
              29.1 There exists a material risk that regulatory authorities,
              tribunals and/or courts having jurisdiction over SOLmate and its
              Users in terms of Applicable Laws may differ in their
              interpretation of the Applicable Laws, may promulgate laws,
              regulations, advisory notes or industry recommendations in future
              that may potentially have an adverse effect on SOLmate and its
              ability to administrate the SOLmate Platform as intended and you
              are advised to note that SOLmate reserves the right to
              unilaterally alter the terms and conditions to comply with the
              relevant Applicable Laws as amended and as required from time to
              time. SOLmate may further make any reasonable changes to its
              process or take any reasonable steps necessary (which may include
              suspension of access to the SOLmate Platform to Users) to adhere
              to Applicable Laws and rulings, orders, notices or other
              recommendations from regulatory authorities and shall accordingly
              inform all participants in writing of such changes or steps taken.
            </p>
          </ol>
          <br />

          <ol>
            <b>30. GENERAL</b>
            <p>
              30.1. <b>Dispute</b>: If any dispute arises in relation to the
              Services, the SOLmate Platform, your SOLmate Account or these
              Terms, you will notify SOLmate of the dispute and the parties will
              use their best efforts to resolve the dispute and attempt informal
              resolution of without instituting legal action in any court or
              other similar forum.
            </p>
            <p>
              30.2. <b>Suspension of the Website:</b> we may temporarily suspend
              the Website or SOLmate Platform for any reason, including repairs
              or upgrades to the Website or other systems. SOLmate will take
              reasonable efforts to notify Users of such suspensions in advance.
            </p>
            <p>
              30.3. <b>Entire agreement:</b> these Terms constitute the whole
              agreement between the parties relating to the subject matter of
              these Terms and supersedes any other discussions, agreements
              and/or understandings regarding the subject matter of these Terms.
            </p>
            <p>
              30.4. <b>Confidentiality:</b> neither party shall disclose any
              confidential information to any third party without the prior
              written approval of the other party, unless required by law.
            </p>
            <p>
              30.5. <b>Law and jurisdiction:</b> these Terms and all obligations
              connected to them or arising from them shall be governed and
              interpreted in terms of the laws of the South Africa. Each party
              submits to the jurisdiction of the courts in the South Africa.
            </p>
            <p>
              30.6. <b>Good faith:</b> the parties shall in their dealings with
              each other display good faith.
            </p>
            <p>
              30.7. <b>Breach:</b> subject to any other provisions in these
              Terms allowing for immediate cancellation or suspension of your
              SOLmate Account, if either party to these Terms breaches any
              material provision or term of these Terms and fails to remedy such
              breach within 5 (five) calendar days of receipt of written notice
              requiring it to do so then the aggrieved party shall be entitled
              without notice, in addition to any other remedy available to it at
              law or under these Terms (including obtaining an interdict), to
              cancel these Terms or to claim specific performance of any
              obligation whether or not the due date for performance has
              arrived, in either event without prejudice to the aggrieved
              party's right to claim damages.
            </p>
            <p>
              30.8. <b>No waiver:</b> the failure of SOLmate to insist upon or
              enforce strict performance by the User of any provision of these
              Terms, or to exercise any right under these Terms, shall not be
              construed as a waiver or relinquishment of SOL's right to enforce
              any such provision or right in any other instance.
            </p>
            <p>
              30.9. <b>No assignment:</b> the User will not be entitled to cede
              its rights or delegate its obligations in terms of these Terms
              without the express prior written consent of SOLmate.
            </p>
            <p>
              30.10. <b>Relationship between the parties:</b> the parties agree
              that neither party is a partner or agent of the other party and
              neither party will have any right, power, or authority to enter
              into any agreement for, or on behalf of, or incur any obligation
              or liability of, or to otherwise bind, the other party.
            </p>
            <p>
              30.11. <b>No representation:</b> to the extent permissible by law,
              no party shall be bound by any express or implied or tacit term,
              representation, warranty, promise or the like not recorded herein,
              whether it induced the contract and/or whether it was negligent or
              not.
            </p>
            <p>
              30.12. <b>Severability:</b> any provision in these Terms which is
              or may become illegal, invalid or unenforceable shall be
              ineffective to the extent of such prohibition or unenforceability
              and shall be severed from the balance of these Terms, without
              invalidating the remaining provisions of these Terms.
            </p>
            <p>
              30.13. <b>No stipulation:</b> no part of these Terms shall
              constitute a stipulation in favour of any person who is not a
              party to these Terms unless the provision in question expressly
              provides that it does constitute such a stipulation.
            </p>
            <p>
              30.14. <b>Notices:</b>
            </p>
            <ol>
              <p>
                30.14.1. SOLmate selects support@solmate.co.za as its email
                address for the service of all formal notices and legal
                processes in connection with these Terms, which may be updated
                from time to time by updating these Terms.
              </p>
              <p>
                30.14.2. You hereby select the SOLmate Platform Chat service in
                your SOLmate Account for service of all formal notices and legal
                processes in connection with these Terms. It is your
                responsibility to provide us with an email address for notice if
                your SOLmate Account is closed.
              </p>
              <p>
                30.14.3. Service via email or electronic platforms shall be
                accepted in all cases where notice is required unless
                alternative service is required by law. Service via email is
                deemed to be received at the time and day of sending.
              </p>
            </ol>
          </ol>

          <div id="volume-booster-visusalizer">
            <div class="sound">
              <div class="sound-icon"></div>
              <div class="sound-wave sound-wave_one"></div>
              <div class="sound-wave sound-wave_two"></div>
              <div class="sound-wave sound-wave_three"></div>
            </div>
            <div class="segments-box">
              <div data-range="1-20" class="segment">
                <span></span>
              </div>
              <div data-range="21-40" class="segment">
                <span></span>
              </div>
              <div data-range="41-60" class="segment">
                <span></span>
              </div>
              <div data-range="61-80" class="segment">
                <span></span>
              </div>
              <div data-range="81-100" class="segment">
                <span></span>
              </div>
            </div>
          </div>
        </main>
      </Layer>
    </>
  )
}

export default TermsAndContidions
